<template>
  <el-dialog
      v-model="isShow"
      title="节点详情"
      width="70%"
      :before-close="handleClose"
  >
    <template #default>

      <div class="table-btn-list">
        <el-button
            class="table-btn"
            type="primary"
            @click="handleAdd"
        >
          新增
        </el-button>
      </div>

      <el-table v-loading="loading" :data="nodeList">
        <el-table-column>
          <template #default="scope">
            <el-descriptions :column="6" :title="scope.row.name">
              <template #extra>
                <el-tooltip content="置顶" v-if="scope.row.sortNum !== 1">
                  <el-icon :size="20" style="margin-right:10px" @click="topping(scope.row)">
                    <Top color="#00B7FF"/>
                  </el-icon>
                </el-tooltip>
                <el-icon v-else :size="20" style="margin-right:10px"></el-icon>

                <el-tooltip content="下移" v-if="scope.row.sortNum !== nodeList.length">
                  <el-icon :size="20" style="margin-right:10px" @click="down(scope.row)">
                    <Bottom color="#00B7FF" />
                  </el-icon>
                </el-tooltip>
                <el-icon v-else :size="20" style="margin-right:10px"></el-icon>

                <el-tooltip content="编辑">
                  <el-icon :size="20" style="margin-right:10px" @click="handleUpdate(scope.row)">
                    <Edit color="#00B7FF" />
                  </el-icon>
                </el-tooltip>

                <el-tooltip content="删除">
                  <el-icon :size="20" color="#ff0000" @click="handleDelete(scope.row)">
                    <Delete/>
                  </el-icon>
                </el-tooltip>
              </template>
              <el-descriptions-item label="节点描述：">{{ scope.row.description }}</el-descriptions-item>
            </el-descriptions>
          </template>
        </el-table-column>
      </el-table>

      <pagination
          v-show="total>0"
          :total="total"
          v-model:page="queryParams.pageNum"
          v-model:limit="queryParams.pageSize"
          @pagination="getList"
      />

      <!-- 添加或修改节点对话框 -->
      <el-dialog :title="title" v-model="open" width="500px" append-to-body>
        <el-form ref="form" :model="form" :rules="rules" label-width="80px">
          <el-form-item label="节点名称" prop="name">
            <el-input v-model="form.name" maxlength="50" placeholder="请输入节点名称"/>
          </el-form-item>
          <el-form-item label="节点描述" prop="description">
            <el-input v-model="form.description" maxlength="100" placeholder="请输入节点描述"/>
          </el-form-item>
        </el-form>
        <template #footer>
      <span class="dialog-footer">
        <el-button type="primary" @click="submitForm" :disabled="submitting">确 定</el-button>
          <el-button @click="cancel">取 消</el-button>
      </span>
        </template>
      </el-dialog>
    </template>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="handleClose">取消</el-button>
      </span>
    </template>
  </el-dialog>
</template>

<script>
import {listNode, getNode, delNode, addNode, updateNode, topNode, downNode} from "@/api/node";
import {isResOK} from "@/api/response";
import pagination from "@/components/Pagination/index.vue";

export default {
  name: "Node",
  components: {pagination},
  props: {
    nodeTemplateId: {
      type: Object,
      required: true,
    },

    visible: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      // 遮罩层
      loading: true,
      // 选中数组
      ids: [],
      // 非单个禁用
      single: true,
      // 非多个禁用
      multiple: true,
      // 显示搜索条件
      showSearch: true,
      // 总条数
      total: 0,
      // 节点表格数据
      nodeList: [],
      // 弹出层标题
      title: "",
      // 是否显示弹出层
      open: false,
      // 查询参数
      queryParams: {
        pageNum: 1,
        pageSize: 10,
      },
      // 表单参数
      form: {},
      // 表单校验
      rules: {
        name: [
          {required: true, message: "节点名称不能为空", trigger: "blur"}
        ],
        description: [
          {required: true, message: "节点描述不能为空", trigger: "blur"}
        ],
      },
      submitting: false,
    };
  },
  computed: {
    isShow: {
      get() {
        return this.visible
      },

      set(val) {
        this.$emit('update:visible', val)
      },
    },
  },
  created() {
    this.getList();
  },
  methods: {
    /** 查询节点列表 */
    getList() {
      this.loading = true;
      this.queryParams.nodeTemplateId = this.nodeTemplateId
      listNode(this.queryParams).then(response => {
        if (isResOK(response)) {
          this.nodeList = response.rows;
          this.total = response.total;
        }
        this.loading = false;
        this.submitting = false
      });
    },
    // 取消按钮
    cancel() {
      this.open = false;
      this.reset();
    },
    // 表单重置
    reset() {
      this.form = {
        id: null,
        name: null,
        description: null,
        nodeTemplateId: null,
        sortNum: null,
        createTime: null,
        updateTime: null
      };
      this.resetForm("form");
    },
    /** 搜索按钮操作 */
    handleQuery() {
      this.queryParams.pageNum = 1;
      this.getList();
    },
    /** 重置按钮操作 */
    resetQuery() {
      this.resetForm("queryForm");
      this.handleQuery();
    },
    // 多选框选中数据
    handleSelectionChange(selection) {
      this.ids = selection.map(item => item.id)
      this.single = selection.length !== 1
      this.multiple = !selection.length
    },
    /** 新增按钮操作 */
    handleAdd() {
      this.reset();
      this.open = true;
      this.title = "添加节点";
    },
    /** 修改按钮操作 */
    handleUpdate(row) {
      this.reset();
      const id = row.id || this.ids
      getNode(id).then(response => {
        if (isResOK(response)) {
          this.form = response.data;
        }
        this.open = true;
        this.title = "修改节点";
      });
    },
    /** 提交按钮 */
    submitForm() {
      this.submitting = true
      this.$refs["form"].validate(valid => {
        if (valid) {
          if (this.form.id != null) {
            this.form.nodeTemplateId = this.nodeTemplateId
            updateNode(this.form).then(response => {
              if (isResOK(response)) {
                this.$message({
                  message: '修改成功',
                  type: 'success',
                })
                this.open = false;
                this.getList();
                this.submitting = false
              } else {
                this.submitting = false
              }
            });
          } else {
            this.form.nodeTemplateId = this.nodeTemplateId
            addNode(this.form).then(response => {
              if (isResOK(response)) {
                this.$message({
                  message: '新增成功',
                  type: 'success',
                })
                this.open = false;
                this.getList();
                this.submitting = false
              } else {
                this.submitting = false
              }
            });
          }
        } else {
          this.submitting = false
        }
      });
    },
    /** 删除按钮操作 */
    handleDelete(row) {
      const ids = row.id || this.ids;
      this.$messageBox.confirm('是否确认删除节点"' + row.name + '"', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      },).then(() => {
        delNode(ids).then((res) => {
          if (isResOK(res)) {
            this.$message({message: '删除成功', type: 'success',})
          }
          this.getList()
        })
      }).catch(() => {
      });
    },
    /** 导出按钮操作 */
    handleExport() {
      this.download('business/node/export', {
        ...this.queryParams
      }, `node_${new Date().getTime()}.xlsx`)
    },
    handleClose() {
      this.$emit('completed')
      this.isShow = false
    },
    topping(row) {
      topNode(row.id).then(res => {
        if (isResOK(res)){
          this.$message({
            message: '置顶成功',
            type: 'success',
          })
          this.getList();
        }
      })
    },
    down(row) {
      downNode(row.id).then(res => {
        if (isResOK(res)){
          this.$message({
            message: '下移成功',
            type: 'success',
          })
          this.getList();
        }
      })
    },
  }
};
</script>
